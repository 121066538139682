import {
	ApolloClient,
	ApolloProvider,
	createHttpLink,
	DefaultOptions,
	InMemoryCache,
	split,
} from '@apollo/client';
import { } from '@apollo/client/link/batch';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { NavigationContainer } from '@react-navigation/native';
import { createUploadLink } from 'apollo-upload-client';
import { StatusBar } from 'expo-status-bar';
import { createClient } from 'graphql-ws';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Provider } from 'react-redux';
import io from 'socket.io-client';
import useGetUser from './app/api/auth/useGetUser';
import useLogout from './app/api/auth/useLogout';
import { onCompletedTag } from './app/api/config/onCompleted';
import { useGetTags } from './app/api/config/useGetTags';
import Alerts from './app/components/common/alerts/alerts';
import Loader from './app/components/common/loader/Loader';
import { GET_ADMIN_BALANCE } from './app/graphql/auth/queries';
import { useFonts } from './app/hooks/useCutomFonts';
import { useGetAdminBalance } from './app/hooks/useGetAdminBalance';
import { useIsSpecificViewportWidth } from './app/hooks/useIsSpecificViewportWidth';
import { linking } from './app/navigation/Linking';
import { ScreenConfig } from './app/navigation/ScreenConfig';
import { Stack } from './app/navigation/Stack';
import store, { useAppDispatch, useAppSelector } from './app/redux/store';
import { setScreenWidth } from './app/redux/uiSlice';
import './index.web.css';

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'network-only',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	},
	mutate: {
		errorPolicy: 'all',
	},
};
export const serverLink = 'https://ocpsersta.punchapps.cool/graphql';
// 'http://localhost:3030/graphql';
// uri: 'https://server.ocpus.io',
// uri: 'https://ocpus-server-staging.herokuapp.com',
// uri: 'https://ocpus-server-development.herokuapp.com',
const LINK = {
	credentials: 'include',
	uri: serverLink + '/graphql',
};

const uploadLink = createUploadLink(LINK);
const httpLink = createHttpLink(LINK);

const wsLink = new GraphQLWsLink(
	createClient({
		url: 'wss://server.ocpus.io/graphql',
		// 'ws://localhost:3030/graphql',
		// url: 'wss://server.ocpus.io/graphql',
		// url: 'https://ocpus-server-staging.herokuapp.com/graphql',
		// url: 'https://ocpus-server-development.herokuapp.com/graphql',
	})
);

export const socket = io(``, {
	path: '',
});

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	uploadLink.concat(httpLink)
);

const client = new ApolloClient({
	// link: ApolloLink.from([uploadLink, httpLink, wsLink]),
	link: splitLink.concat(uploadLink),
	cache: new InMemoryCache(),
	defaultOptions,
});

// const LINK = {
// 	credentials: 'include',
// 	uri: 'http://localhost:3030/graphql',
// 	// uri: 'http://35.180.83.242:8080/graphql',
// 	// uri: "https://ocpus-server.herokuapp.com/graphql"
// };

// const client = new ApolloClient({
// 	link: ApolloLink.from([uploadLink, httpLink]),
// 	cache: new InMemoryCache(),
// 	defaultOptions,
// });

const Main = () => {
	const { width } = useIsSpecificViewportWidth();
	const dispatch = useAppDispatch();

	const { isUserLoggedIn, loggedInUserDetails } = useAppSelector(
		({ auth: { isUserLoggedIn, loggedInUserDetails } }) => ({
			isUserLoggedIn,
			loggedInUserDetails,
		})
	);

	const { loading, data: userApiData } = useGetUser();

	const userData = userApiData?.getUser?.data;

	const { executeLogoutQuery } = useLogout();

	const ALLOWED_TYPEOFACCOUNTS = ['SUPER_ADMIN', 'ADMIN'];

	useEffect(() => {
		if (userData?._id) {
			if (!ALLOWED_TYPEOFACCOUNTS.includes(userData?.typeOfAccount)) {
				executeLogoutQuery();
			}
		}
	}, [userData]);

	useEffect(() => {
		if (loggedInUserDetails?._id) {
			socket.emit('addUser', loggedInUserDetails?._id);
		}
	}, [loggedInUserDetails?._id]);

	const { getAppTags, data } = useGetTags();

	useEffect(() => {
		dispatch(setScreenWidth(width));
		useFonts().then(() => null);
	}, [width]);
	useEffect(() => {
		getAppTags({ onCompleted: data => onCompletedTag(data, dispatch) });
	}, []);

	return (
		<View style={styles.container}>
			{loading ? (
				<Loader fullView />
			) : (
				<>
					<Alerts />
					<NavigationContainer linking={linking}>
						<Stack.Navigator>
							<>
								{isUserLoggedIn ? (
									<>
										{/* TODO: Refactor code */}
										{/* Protected Route */}
										{ScreenConfig.filter(
											({ protectedRoute = true, ...args }) => {
												if (loggedInUserDetails?.role === "BRAND_ADMIN") {
													return protectedRoute === true && args?.isShowForBrandAdmin === true
												} else {
													return protectedRoute === true
												}
											}).map(
												({
													protectedRoute = true,
													...args
												}) => (
													<Stack.Screen
														key={args.name}
														{...args}
													/>
												)
											)}
									</>
								) : (
									<>
										{/* UnProtected Route */}
										{ScreenConfig.filter(
											({ protectedRoute = true }) =>
												protectedRoute === false
										).map(
											({
												protectedRoute = true,
												...args
											}) => (
												<Stack.Screen
													key={args.name}
													{...args}
												/>
											)
										)}
									</>
								)}
							</>
						</Stack.Navigator>
						<StatusBar style="auto" />
					</NavigationContainer>
				</>
			)}
		</View>
	);
};

export default function App() {
	return (
		<ApolloProvider client={client}>
			<Provider store={store}>
				<Main />
			</Provider>
		</ApolloProvider>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: '#fff',
		overflowX: 'hidden',
	},
});

// figma mobile dimensions:
// width: 375
// height: 812
// figma web dimensions:
// width: 1440
// height: 1000
